// App.tsx
import React, { useState } from 'react';
import { Home, Code, Info, Mail, Menu } from 'lucide-react';

// Define the possible sections of the website
type Section = 'home' | 'services' | 'about' | 'contact';

// Props interface for the Navigation component
interface NavigationProps {
  activeSection: Section;
  setActiveSection: (section: Section) => void;
}

// Navigation component
const Navigation: React.FC<NavigationProps> = ({ activeSection, setActiveSection }) => {
  // State to control the visibility of the mobile menu
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="bg-gray-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <h1 className="text-2xl font-bold">VigorTec</h1>

        {/* Desktop menu */}
        <div className="hidden md:flex space-x-4">
          {['home', 'services', 'about', 'contact'].map((section) => (
            <button
              key={section}
              onClick={() => setActiveSection(section as Section)}
              className={`flex items-center ${activeSection === section ? 'text-blue-400' : ''}`}
            >
              {section === 'home' && <Home size={18} className="mr-1" />}
              {section === 'services' && <Code size={18} className="mr-1" />}
              {section === 'about' && <Info size={18} className="mr-1" />}
              {section === 'contact' && <Mail size={18} className="mr-1" />}
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </button>
          ))}
        </div>

        {/* Mobile menu button */}
        <button className="md:hidden" onClick={toggleMobileMenu}>
          <Menu size={24} />
        </button>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="md:hidden mt-4">
          {['home', 'services', 'about', 'contact'].map((section) => (
            <button
              key={section}
              onClick={() => {
                setActiveSection(section as Section);
                setIsMobileMenuOpen(false);
              }}
              className={`block w-full text-left py-2 px-4 ${activeSection === section ? 'bg-gray-700' : ''
                }`}
            >
              {section.charAt(0).toUpperCase() + section.slice(1)}
            </button>
          ))}
        </div>
      )}
    </nav>
  );
};

// Home section component
const HomeSection: React.FC = () => (
  <section className="py-20 bg-gray-100">
    <div className="container mx-auto text-center">
      <h2 className="text-4xl font-bold mb-4">VigorTec - Custom Software Development Solutions</h2>
      <p className="text-xl mb-8">We specialize in creating personalized software solutions for small businesses. Our expertise and commitment to quality ensure that your unique needs are met with precision, affordability, and vigor.</p>
      {/* <button className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300">Get Started</button> */}
    </div>
  </section>
);

// Services section component
const Services: React.FC = () => (
  <section className="py-20">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold mb-8 text-center">Our Services</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {['Custom Software Development', 'Web Application Development', 'Database Design and Management', 'Cloud Integration Services'].map((service, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-4">{service}</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
          </div>
        ))}
      </div>
    </div>
  </section>
);

// About section component
const About: React.FC = () => (
  <section className="py-20 bg-gray-100">
    <div className="container mx-auto">
      <h2 className="text-3xl font-bold mb-8 text-center">About Us</h2>
      <p className="text-lg mb-6">VigorTec is run by a seasoned software developer with 20 years of industry experience. After two decades of working on large-scale projects, our founder decided to focus on creating more personalized solutions for small businesses.</p>
      <p className="text-lg">We believe that every business, regardless of its size, deserves high-quality, tailored software solutions that can drive growth and efficiency.</p>
    </div>
  </section>
);

// Contact section component
const Contact: React.FC = () => (
  <section className="py-20">
    <div className="container mx-auto text-center">
      <h2 className="text-3xl font-bold mb-8">Contact Us</h2>
      <p className="text-xl mb-8">Ready to elevate your business with custom software? Get in touch with us today!</p>
      <a href="mailto:vigortec@proton.me" className="bg-blue-600 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-700 transition duration-300">Email Us</a>
    </div>
  </section>
);

// Footer component
const Footer: React.FC = () => (
  <footer className="bg-gray-800 text-white py-8">
    <div className="container mx-auto text-center">
      <p>&copy; 2024 VigorTec. All rights reserved.</p>
    </div>
  </footer>
);

// Main App component
const App: React.FC = () => {
  // State to keep track of the active section
  const [activeSection, setActiveSection] = useState<Section>('home');

  return (
    <div className="flex flex-col min-h-screen">
      <Navigation activeSection={activeSection} setActiveSection={setActiveSection} />
      <main className="flex-grow">
        {activeSection === 'home' && <HomeSection />}
        {activeSection === 'services' && <Services />}
        {activeSection === 'about' && <About />}
        {activeSection === 'contact' && <Contact />}
      </main>
      <Footer />
    </div>
  );
};

export default App;
